var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "el-zoom-in-center" } }, [
        _vm.formShow
          ? _c("div", { staticClass: "formBox" }, [
              _c("div", { staticClass: "formTitleBox" }, [
                _c("div", { staticClass: "leftBack" }, [
                  _c(
                    "div",
                    {
                      staticClass: "backBox",
                      on: {
                        click: function ($event) {
                          _vm.formShow = false
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-back" }),
                      _vm._v("\n            返回\n          "),
                    ]
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "20px",
                        "margin-left": "40px",
                      },
                    },
                    [_vm._v(_vm._s(_vm.formTitle))]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "rightBtn" },
                  [
                    _c(
                      "el-button-group",
                      [
                        _vm.formType != "view"
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.submitAllForm(1)
                                  },
                                },
                              },
                              [_vm._v("保存")]
                            )
                          : _vm._e(),
                        _vm.formType != "view"
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.submitAllForm(2)
                                  },
                                },
                              },
                              [_vm._v("保存并返回")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.closeForm },
                          },
                          [_vm._v("取消")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "formContentBox" }, [
                _c("div", { staticClass: "formMain" }, [
                  _c("div", [
                    _c(
                      "div",
                      { staticStyle: { padding: "0 12px" } },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "dataForm",
                            attrs: {
                              model: _vm.dataForm,
                              "label-width": "150px",
                              rules: _vm.rules,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "formTopic",
                                staticStyle: { "margin-bottom": "12px" },
                              },
                              [_vm._v("基本信息")]
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "任务编码",
                                          prop: "taskCode",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            disabled: "",
                                            placeholder: "系统自动生成",
                                          },
                                          model: {
                                            value: _vm.dataForm.taskCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dataForm,
                                                "taskCode",
                                                $$v
                                              )
                                            },
                                            expression: "dataForm.taskCode",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "任务名称",
                                          prop: "taskName",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入任务名称",
                                          },
                                          model: {
                                            value: _vm.dataForm.taskName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dataForm,
                                                "taskName",
                                                $$v
                                              )
                                            },
                                            expression: "dataForm.taskName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "状态",
                                          prop: "acquisitionStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "请选择状态",
                                              disabled: "",
                                            },
                                            model: {
                                              value:
                                                _vm.dataForm.acquisitionStatus,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "acquisitionStatus",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm.acquisitionStatus",
                                            },
                                          },
                                          _vm._l(
                                            _vm.publishStatusOption,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.dictKey,
                                                attrs: {
                                                  label: item.dictValue,
                                                  value: item.dictKey,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "编制人",
                                          prop: "weaveUser",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            disabled: "",
                                            placeholder: "系统自动生成",
                                          },
                                          model: {
                                            value: _vm.dataForm.weaveUser,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dataForm,
                                                "weaveUser",
                                                $$v
                                              )
                                            },
                                            expression: "dataForm.weaveUser",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "编制单位",
                                          prop: "weaveDept",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            disabled: "",
                                            placeholder: "系统自动生成",
                                          },
                                          model: {
                                            value: _vm.dataForm.weaveDept,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dataForm,
                                                "weaveDept",
                                                $$v
                                              )
                                            },
                                            expression: "dataForm.weaveDept",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "模板编码",
                                          prop: "templateCode",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "请选择模板编码",
                                              clearable: "",
                                            },
                                            on: { change: _vm.chooseTemplate },
                                            model: {
                                              value: _vm.dataForm.templateCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "templateCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm.templateCode",
                                            },
                                          },
                                          _vm._l(
                                            _vm.templateOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.templateCode,
                                                attrs: {
                                                  label: item.templateName,
                                                  value: item.templateCode,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "formTopic",
                                staticStyle: { "margin-bottom": "12px" },
                              },
                              [_vm._v("填报要求")]
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 20 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "标题：",
                                          prop: "title",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "title",
                                            placeholder: "请输入标题",
                                          },
                                          model: {
                                            value: _vm.dataForm.title,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dataForm,
                                                "title",
                                                $$v
                                              )
                                            },
                                            expression: "dataForm.title",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 20 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "角色：",
                                          prop: "roleId",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "请选择角色",
                                            },
                                            on: { change: _vm.chooseRole },
                                            model: {
                                              value: _vm.dataForm.roleId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "roleId",
                                                  $$v
                                                )
                                              },
                                              expression: "dataForm.roleId",
                                            },
                                          },
                                          _vm._l(_vm.roleData, function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.roleName,
                                                value: item.id,
                                              },
                                            })
                                          }),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 20 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "填报截至日期：",
                                          prop: "deadlineFillingDate",
                                        },
                                      },
                                      [
                                        _c("el-date-picker", {
                                          attrs: {
                                            type: "date",
                                            format: "yyyy-MM-dd HH:mm:ss",
                                            "value-format":
                                              "yyyy-MM-dd HH:mm:ss",
                                            placeholder: "请选择采集时间",
                                          },
                                          model: {
                                            value:
                                              _vm.dataForm.deadlineFillingDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dataForm,
                                                "deadlineFillingDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "dataForm.deadlineFillingDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 20 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "汇总截至日期：",
                                          prop: "deadlineSummaryDate",
                                        },
                                      },
                                      [
                                        _c("el-date-picker", {
                                          attrs: {
                                            type: "date",
                                            format: "yyyy-MM-dd HH:mm:ss",
                                            "value-format":
                                              "yyyy-MM-dd HH:mm:ss",
                                            placeholder: "请选择采集时间",
                                          },
                                          model: {
                                            value:
                                              _vm.dataForm.deadlineSummaryDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dataForm,
                                                "deadlineSummaryDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "dataForm.deadlineSummaryDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "上传附件：" } },
                                      [
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "upload-demo",
                                            attrs: {
                                              action:
                                                "api/sinoma-resource/oss/endpoint/put-file",
                                              "on-success": _vm.handleSuccess,
                                              "on-remove": _vm.handleRemove,
                                              headers: _vm.headers,
                                              multiple: "",
                                              limit: 1,
                                              "file-list": _vm.fileList,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "small",
                                                  type: "primary",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "点击上传\n                        "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "el-upload__tip",
                                                attrs: { slot: "tip" },
                                                slot: "tip",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          支持扩展名：.rar .zip .doc .docx .pdf .jpg...\n                        "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 20 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "填写说明：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { type: "textarea" },
                                          model: {
                                            value:
                                              _vm.dataForm
                                                .acquisitionDescription,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dataForm,
                                                "acquisitionDescription",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "dataForm.acquisitionDescription",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "formTopic" }, [
                          _vm._v("填报单位"),
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              padding: "12px",
                              display: "flex",
                              "justify-content": "center",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: { input: _vm.chooseGroup },
                                model: {
                                  value: _vm.groupRadio,
                                  callback: function ($$v) {
                                    _vm.groupRadio = $$v
                                  },
                                  expression: "groupRadio",
                                },
                              },
                              _vm._l(_vm.groupOption, function (item, index) {
                                return _c(
                                  "el-radio",
                                  {
                                    key: index,
                                    attrs: { label: item.id, border: "" },
                                  },
                                  [_vm._v(_vm._s(item.groupName))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "shuttleBackBox" }, [
                          _c("div", { staticClass: "shutleBack" }, [
                            _c("div", { staticClass: "shutleTitle" }, [
                              _vm._v("组织架构"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "shutContent" },
                              [
                                _c("el-tree", {
                                  ref: "tree",
                                  attrs: {
                                    data: _vm.deptTree,
                                    props: _vm.defaultProps,
                                    "node-key": "id",
                                  },
                                  on: { "node-click": _vm.handleNodeClick },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "leftRightBtn" },
                            [
                              _c(
                                "el-button-group",
                                [
                                  _c("el-button", {
                                    attrs: {
                                      type: "primary",
                                      size: "medium",
                                      icon: "el-icon-arrow-left",
                                    },
                                  }),
                                  _c("el-button", {
                                    attrs: {
                                      type: "primary",
                                      size: "medium",
                                      icon: "el-icon-arrow-right",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "shutleBack" }, [
                            _c("div", { staticClass: "shutleTitle" }, [
                              _vm._v("填报单位"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "shutContent" },
                              _vm._l(
                                _vm.middleShutData,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "deptBox" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "dark",
                                            content: item.organize,
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.organize)),
                                          ]),
                                        ]
                                      ),
                                      _c("i", {
                                        staticClass: "el-icon-delete",
                                        staticStyle: {
                                          color: "#f56c6c",
                                          "font-size": "20px",
                                          "line-height": "unset",
                                          cursor: "pointer",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteMiddleShut(
                                              item,
                                              index
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "leftRightBtn" },
                            [
                              _c(
                                "el-button-group",
                                [
                                  _c("el-button", {
                                    attrs: {
                                      type: "primary",
                                      size: "medium",
                                      icon: "el-icon-arrow-left",
                                    },
                                  }),
                                  _c("el-button", {
                                    attrs: {
                                      type: "primary",
                                      size: "medium",
                                      icon: "el-icon-arrow-right",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "shutleBack" }, [
                            _c("div", { staticClass: "shutleTitle" }, [
                              _vm._v("汇总单位"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "shutContent" },
                              _vm._l(_vm.rightShutData, function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "deptBox" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: item.organize,
                                          placement: "top-start",
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.organize)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }